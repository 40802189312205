/* eslint-disable react/prop-types */
import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PulseLoader from "react-spinners/PulseLoader";

export default function DummyToken(props) {
  return (
    <Card>
      <GridContainer style={{ minHeight: 500 }}>
        {/* <GridItem xs={12} sm={12} md={6} lg={6} className={"loadingCard"}>
                    <Card className={"loadingCard"}>
                        <CardToken color="grey" className={"loadingCard"}>
                            <div className="container loadingCard">
                                <CardMedia style={{ height: 400, maxHeight: 400 }} image={""} />
                                <div class="overlayLoading">
                                    <div class="textBlack">
                                        <div className="sweet-loading">
                                            <PulseLoader
                                                color={"#008CBA"}
                                                loading={true}
                                            />
                                        </div>
                                        {"Loading Preview"}</div>
                                </div>
                            </div>
                        </CardToken>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange />
                                {"**** ***** ***"}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem> */}
        <GridItem xs={12} sm={12} md={12} lg={12} className={"loadingCard"}>
          <Card className={"loadingCard"}>
            <CardBody className={"loadingCard"}>
              <div className="container loadingCard">
                <div style={{ height: 300, maxHeight: 400 }}></div>
                <div className="overlayLoading">
                  <div className="textBlack">
                    <div className="sweet-loading">
                      <PulseLoader color={"#008CBA"} loading={true} />
                    </div>
                    {props.loadingText}
                  </div>
                </div>
              </div>
            </CardBody>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Card>
  );
}
