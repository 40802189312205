/* eslint-disable */

import React, { useEffect, useState } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import Art from "views/Token/Components/Art";
import Ticket from "views/Token/Components/Ticket";
import Certificate from "views/Token/Components/Certificate";
import Badge from "views/Token/Components/Badge";
import Record from "views/Token/Components/Record";
import Token from "views/Token/Components/Token";
import { useSnackbar } from 'notistack'

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Button, IconButton } from "@material-ui/core";
import InsertLink from '@material-ui/icons/InsertLink'
import { convertKeyPair } from "ed2curve";
import FileCopy from "@material-ui/icons/FileCopy";
export default function TokenMorpher(props) {
  let issuerAlias = "";
  const issuerNames = props.item.issuerAlias;
  const issuerNameBreakdown = issuerNames.split(" ");
  issuerNameBreakdown.map((issuer) => {
    issuerAlias += issuer.charAt(0).toUpperCase() + issuer.substring(1) + " ";
  });

  // const issuerAlias = props.item.issuerAlias.charAt(0).toUpperCase() + props.item.issuerAlias.substring(1);
  const userAlias =
    props.alias.charAt(0).toUpperCase() + props.alias.substring(1);
  const tokenName =
    props.item.tokenName.charAt(0).toUpperCase() +
    props.item.tokenName.substring(1);

  const [tokenDetails, setTokenDetails] = useState(
    tokenName + " was issued by " + issuerAlias + " to " + userAlias
  );
  const [chainId, setChainId] = useState("1")
  const [isEvmSFT, setIsEvmSFT] = useState(false)
  const [tokenId, setTokenId] = useState(0)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    switch (props.item.blockchain) {
      case "ETHEREUM": setChainId("1"); break;
      case "BSC": setChainId("56"); break;
      case "MATIC": setChainId("137"); break;
      case "RINKEBY": setChainId("4"); break;
      case "BSCTESTNET": setChainId("97"); break;
      case "MUMBAI": setChainId("80001"); break;
      default: setChainId("1");
    }
  }, [])

  const copyMessage = val => {
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = val
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
  }

  useEffect(() => {
    if (props.item.contractId && !props.item.tokenId && props.item.tokenIds && props.item.tokenIds.length > 0) {
      setIsEvmSFT(true);
      setTokenId(props.item.tokenIds[0])
    }
  }, [])

  return (
    <div>
      {props.item.category === "DIGITALART" && <Art item={props.item} />}
      {props.item.category === "CERTIFICATE" && (
        <Certificate item={props.item} />
      )}
      {props.item.category === "BADGE" && <Badge item={props.item} />}
      {props.item.category === "TOKEN" && <Token item={props.item} setTokenId={setTokenId} tokenIds={props.tokenIds}/>}{" "}
      {props.item.category === "RECORD" && <Record item={props.item} />}
      {props.item.category === "TICKET" && (
        <Ticket
          item={props.item}
          eventName={props.item.eventName}
          edition={props.item.edition}
          image={props.item.previewUrl}
          eventDate={props.item.eventDate}
          location={props.item.location}
          price={props.item.price}
          niftronId={props.item._id}
          createdAt={props.item.createdAt}
        />
      )}
      {/* //Corda Certified Developer was issued by R3 to Azeem Ashraf. */}
      {/* url={String(window.location)} -- share current url */}
      {/* https://www.npmjs.com/package/react-share */}
      <Card>
        <CardHeader>
          Share Your{" "}
          {props.item.category.charAt(0) +
            props.item.category.substring(1).toLowerCase()}{isEvmSFT && ` - Token Id ${tokenId}`}
        </CardHeader>
        {props.item.blockchain === "STELLAR" ?

          <CardBody>
            <IconButton>

              <FacebookShareButton
                url={`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey
                  }/${btoa(tokenDetails)}`}
                quote={`${tokenDetails}`}
                hashtag="Niftron"
              >
                <FacebookIcon size={32} round={false} borderRadius={6} />
              </FacebookShareButton>
            </IconButton>

            <IconButton>

              <LinkedinShareButton
                url={`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey
                  }/${btoa(tokenDetails)}`}
                source="Niftron"
                summary={`${tokenDetails}`}
                title={`${tokenDetails}`}
              >
                <LinkedinIcon size={32} round={false} borderRadius={6} />
              </LinkedinShareButton>
            </IconButton>



            <IconButton>

              <FacebookMessengerShareButton
                url={`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey
                  }/${btoa(tokenDetails)}`}
                appId="862394367580761"
                redirectUri={`https://profile.niftron.com/token/${props.item.assetCode}`}
                to=""
              >
                <FacebookMessengerIcon size={32} round={false} borderRadius={6} />
              </FacebookMessengerShareButton>
            </IconButton>

            <IconButton>

              <TwitterShareButton
                url={`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey
                  }/${btoa(tokenDetails)}`}
                title={`${tokenDetails}`}
                hashtags={["Blockchain", "Niftron"]}
              >
                <TwitterIcon size={32} round={false} borderRadius={6} />
              </TwitterShareButton>
            </IconButton>

            <IconButton>

              <WhatsappShareButton
                url={`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey
                  }/${btoa(tokenDetails)}`}
                title={`${tokenDetails}`}
              >
                <WhatsappIcon size={32} round={false} borderRadius={6} />
              </WhatsappShareButton>

            </IconButton>

            <IconButton>
              <TelegramShareButton
                url={`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey
                  }/${btoa(tokenDetails)}`}
                title={`${tokenDetails}`}
              >
                <TelegramIcon size={32} round={false} borderRadius={6} />
              </TelegramShareButton>
            </IconButton>
            <IconButton href={`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey}/${btoa(tokenDetails)}`} target="_blank" >
              <InsertLink />
            </IconButton>
            <IconButton onClick={() => {
              copyMessage(`http://explorer.niftron.com/token/${props.item.assetCode}/${props.publicKey
                }/${btoa(tokenDetails)}`)
              enqueueSnackbar('Token URL Copied!', {
                variant: 'info'
              })
            }}>
              <FileCopy
              />
            </IconButton>


          </CardBody>
          :
          <CardBody>
            <IconButton>

              <FacebookShareButton
                url={`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`}
                quote={`${tokenDetails}`}
                hashtag="Niftron"
              >
                <FacebookIcon size={32} round={false} borderRadius={6} />
              </FacebookShareButton>
            </IconButton>

            <IconButton>

              <LinkedinShareButton
                url={`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`}
                source="Niftron"
                summary={`${tokenDetails}`}
                title={`${tokenDetails}`}
              >
                <LinkedinIcon size={32} round={false} borderRadius={6} />
              </LinkedinShareButton>
            </IconButton>



            <IconButton>

              <FacebookMessengerShareButton
                url={`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`}
                appId="862394367580761"
                redirectUri={`https://profile.niftron.com/token/${props.item.assetCode}`}
                to=""
              >
                <FacebookMessengerIcon size={32} round={false} borderRadius={6} />
              </FacebookMessengerShareButton>
            </IconButton>

            <IconButton>

              <TwitterShareButton
                url={`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`}
                title={`${tokenDetails}`}
                hashtags={["Blockchain", "Niftron"]}
              >
                <TwitterIcon size={32} round={false} borderRadius={6} />
              </TwitterShareButton>
            </IconButton>

            <IconButton>

              <WhatsappShareButton
                url={`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`}
                title={`${tokenDetails}`}
              >
                <WhatsappIcon size={32} round={false} borderRadius={6} />
              </WhatsappShareButton>

            </IconButton>

            <IconButton>
              <TelegramShareButton
                url={`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`}

                title={`${tokenDetails}`}
              >
                <TelegramIcon size={32} round={false} borderRadius={6} />
              </TelegramShareButton>
            </IconButton>
            <IconButton href={`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`} target="_blank" >
              <InsertLink />
            </IconButton>
            <IconButton onClick={() => {
              copyMessage(`http://explorer.niftron.com/nft?chainID=${chainId}&contractID=${props.item.contractId}&tokenID=${isEvmSFT ? tokenId : props.item.tokenId}`)
              enqueueSnackbar('Token URL Copied!', {
                variant: 'info'
              })
            }}>
              <FileCopy


              />
            </IconButton>

          </CardBody>



        }

      </Card>
    </div>
  );
}
