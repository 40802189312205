/* eslint-disable react/prop-types */
import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import { CardMedia } from "@material-ui/core";

// core components
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import PulseLoader from "react-spinners/PulseLoader";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function DummyToken(props) {
  const classes = useStyles();
  return (
    <Card className={"loadingCard"}>
      {/* <CardToken color="grey" className={"loadingCard"}> */}
      <div className="container loadingCard">
        <CardMedia style={{ height: 200, maxHeight: 200 }} image={""} />
        <div className="overlayLoading">
          <div className="textBlack">
            <div className="sweet-loading">
              <PulseLoader color={"#008CBA"} loading={true} />
            </div>
            {props.loadingText}
          </div>
        </div>
      </div>
      {/* </CardToken> */}
      <CardFooter stats>
        <div className={classes.stats}>
          <DateRange />
          {"**** ***** ***"}
        </div>
      </CardFooter>
    </Card>
  );
}
