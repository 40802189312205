/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// @material-ui/core
// core components
import TokenMorpher from "views/Token/Components/TokenMorpher";
import { getUserSession } from "services/UserManagement";
import {
  getTokenById,
  getTokenBalanceFromStellar
} from "services/TokenManagement";
// import Paginations from "components/Pagination/Pagination.js";
import DummyToken from "views/Token/Components/DummyToken";
import Web3 from 'web3'
import { web3Providers, creatorContracts } from '../../variables/constants'

var bscWeb3 = new Web3(web3Providers.providerBscTestNet);
var ethWeb3 = new Web3(web3Providers.providerEthereum);
var maticWeb3 = new Web3(web3Providers.providerMatic);
var bscTestnetWeb3 = new Web3(web3Providers.providerBscTestNet);
var rinkebyWeb3 = new Web3(web3Providers.providerRinkeby);
var mumbaiWeb3 = new Web3(web3Providers.providerMumbai);


export default function Token(props) {
  const { id } = props.match.params;
  const [user] = useState(getUserSession());
  const [token, setToken] = useState(null);
  const [tokenIds, setTokenIds] = useState([]);

  const [loading, setloading] = useState(false);
  const [loadingText, setLoadingText] = useState("loading");
  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      setLoadingText("Verifying Token In Blockchain");
      const balances = await getTokenBalanceFromStellar(user.publicKey);
      setLoadingText("Retrieving MetaData From Niftron");

      let result = await getTokenById(id);
      if (result != null) {
        if (result.data) {
          console.log(result.data);

          if (balances != null) {
            balances.forEach(sToken => {
              if (sToken.assetCode == result.data.assetCode) {
                result.data.assetCount = sToken.balance * 10000000;
              }
            });
          }
          setToken(result.data);

          if (result.data.blockchain && result.data.tokenIds && result.data.tokenIds.length > 0) {

            if (user.accounts && user.accounts.length >= 3) {
              const ethereumKey = user.accounts[2].publicKey
              // console.log(ethereumKey)

              const ERC721ABI = require('./ERC721.json')
              //mumbai
              const contractMumbai = new mumbaiWeb3.eth.Contract(
                ERC721ABI,
                creatorContracts.MUMBAI.CREATOR
              );
              const tokenCountMumbai = await contractMumbai.methods
                .balanceOf(ethereumKey)
                .call({ from: ethereumKey });
              console.log(tokenCountMumbai);

              let ids = []
              for (let j = 0; j < tokenCountMumbai; j++) {
                const tokenId = await contractMumbai.methods
                  .tokenOfOwnerByIndex(ethereumKey, j)
                  .call({ from: ethereumKey });
                if (result.data.tokenIds.includes(parseInt(tokenId))) {
                  ids.push(parseInt(tokenId))
                }
              }


              //matic
              const contractMatic = new maticWeb3.eth.Contract(
                ERC721ABI,
                creatorContracts.MATIC.CREATOR
              );
              const tokenCountMatic = await contractMatic.methods
                .balanceOf(ethereumKey)
                .call({ from: ethereumKey });
              console.log(tokenCountMatic);

              for (let j = 0; j < tokenCountMatic; j++) {
                const tokenId = await contractMatic.methods
                  .tokenOfOwnerByIndex(ethereumKey, j)
                  .call({ from: ethereumKey });
                if (result.data.tokenIds.includes(parseInt(tokenId))) {
                  ids.push(parseInt(tokenId))
                }
              }



              setTokenIds(ids);
            }

          }
        }
        setloading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading && <DummyToken loadingText={loadingText} />}
      {!loading && token && (
        <TokenMorpher
          tokenIds={tokenIds}
          item={token}
          publicKey={user.publicKey}
          alias={user.alias}
        />
      )}
    </div>
  );
}
